/* checkout.css */

.checkout-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
  
  .order-summary {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .order-summary-card {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-content {
    padding: 20px;
  }
  
  .total-amount {
    width: 100%;
    text-align: right;
    margin-top: 10px;
  }
  
  .payment-options {
    width: 35%;
  }
  
  .credit-card-form {
    margin-top: 20px;
  }
  
  .credit-card-form label {
    display: block;
    margin-bottom: 8px;
  }
  
  .credit-card-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
  }
  
  .added-cards {
    margin-top: 20px;
  }
  
  .added-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  