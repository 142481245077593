@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');
/* @import logo from '../assets/vector.svg'; */
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@400;700&family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel_image{
  height: 420px !important;
  object-fit: cover;
}

.imgWrap{
  overflow: hidden !important;
}

.formSec .formBx .flexBx .lft form .row{
  justify-content: end !important;
}

.form-box{
  justify-content: end !important;
}

.swiper-slide.swiper-slide-active {
  opacity: 1 !important;
  background-color: #38261C;
  /* margin-top: 0px !important; */
  transform: translateY(0) !important;
}

.swiper-wrapper {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}