/* src/components/VideoPlayer.css */
.container
  @media only screen and ( min-width: 1441px )
    width: 95%
    max-width: 95%

/* Reset margin and padding for html and body */
html, body
  margin: 0
  padding: 0
  ul
    li
      &::marker
        display: none
        color: transparent
        list-style-type: none
        font-size: 0

.hoveranim
  display: flex
  align-items: center
  justify-content: center
  position: relative
  overflow: hidden
  &::before
    content: ''
    position: absolute
    z-index: 0
    top: 0
    bottom: 0
    width: 100%
    background: #f2a90d4a
    -webkit-transform: scaleX(0)
    -ms-transform: scaleX(0)
    transform: scaleX(0)
    -webkit-transition: -webkit-transform 0.3s ease
    transition: -webkit-transform 0.3s ease
    transition: transform 0.3s ease
    transition: transform 0.3s ease, -webkit-transform 0.3s ease
    left: 0
    -webkit-transform-origin: right
    -ms-transform-origin: right
    transform-origin: right
  span
    position: relative
    z-index: 1
  &:hover
    color: #fff
    &::before
      -webkit-transform-origin: left
      -ms-transform-origin: left
      transform-origin: left
      -webkit-transform: scaleX(1)
      -ms-transform: scaleX(1)
      transform: scaleX(1)
      span
        svg
          fill: #fff
/* Reset margin and padding for all parent elements */
.video-container
  margin: 0
  padding: 0
  position: relative
  height: 100vh

  &::after
    content: ""
    position: absolute
    bottom: 0
    width: 100%
    height: 50%
    background: rgb(102,102,102)
    background: linear-gradient(0deg, rgb(102, 102, 102) 0%, rgba(102, 102, 102, -0.688638) 80%)
    z-index: 0

  & .container
    // position: absolute
  .bannerContent
    position: absolute
    z-index: 1
    left: 0
    right: 0
    max-width: 1625px
    margin: auto
    padding: 0 15px
    bottom: 60px
    @media only screen and ( min-width: 1200px )
      bottom: 100px
    @media only screen and ( min-width: 1441px )
      bottom: 160px
    // top: 0
    // margin: auto
    // max-width: 1000px
    .Hd
      font-weight: 400
      line-height: 1
      color: #fff
      font-family: "Amita", serif
      margin-bottom: 30px
      font-size: 25px
      @media only screen and ( min-width: 768px )
        font-size: 35px
      @media only screen and ( min-width: 992px )
        font-size: 45px
      @media only screen and ( min-width: 1200px )
        font-size: 60px
      @media only screen and ( min-width: 1441px )
        font-size: 70px
      @media only screen and ( min-width: 1551px )
        font-size: 80px
      @media only screen and ( min-width: 1661px )
        font-size: 90px
      @media only screen and ( min-width: 1771px )
        font-size: 110px
    .subHd
      font-weight: 400
      line-height: 1
      color: #fff
      font-family: "Amita", serif
      font-size: 18px
      @media only screen and ( min-width: 768px )
        font-size: 24px
      @media only screen and ( min-width: 992px )
        font-size: 26px
      @media only screen and ( min-width: 1200px )
        font-size: 30px
      @media only screen and ( min-width: 1441px )
        font-size: 34px
      @media only screen and ( min-width: 1551px )
        font-size: 41px
    .btnMore
      display: flex
      align-items: center
      justify-content: center
      width: fit-content
      border: 1px solid #B6AA54
      text-decoration: none
      margin-top: 20px
      height: 45px
      padding: 6px 8px
      @media only screen and ( min-width: 768px )
        margin-top: 30px
        height: 48px
        padding: 8px 10px
      @media only screen and ( min-width: 992px )
        margin-top: 40px
        height: 50px
        padding: 10px 15px
      @media only screen and ( min-width: 1441px )
        height: 55px
        padding: 15px 20px
        margin-top: 50px
      span
        font-size: 20px
        font-family: "Archivo", sans-serif
        font-weight: 400
        color: #fff

.imageBx
  width: 100%
  height: 100%
  overflow: hidden
.fullscreen-video
  width: 100%
  height: 100vh
  object-fit: cover

.video-overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(38, 40, 36, 0.5)

.down-arrow
  position: absolute
  bottom: 20px
  left: 50%
  transform: translateX(-50%)
  width: 0
  height: 0
  border-left: 20px solid transparent
  border-right: 20px solid transparent
  border-top: 30px solid rgb(61, 59, 59)
  animation: bounce 2s infinite

.counterSec
  position: relative
  padding: 25px 0
  .container
    padding: 0 15px
    width: 95%
    max-width: 95%
  &:after
    content: ""
    z-index: 1
    background: #F2A90D
    opacity: .14
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 100%
  .bgElement
    position: absolute
    z-index: 0
    left: 0
    bottom: 0
    width: 100%
    height: 100%
    display: flex
    .lft
      width: 50%
      height: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover

    .ryt
      width: 50%
      height: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover
  .flexBx
    display: flex
    flex-wrap: wrap
    position: relative
    justify-content: center
    z-index: 2
    background: transparent

    .item
      padding: 10px
      width: calc( 100% / 2 )
      @media only screen and ( min-width: 576px )
        width: calc( 100% / 3 )
      @media only screen and ( min-width: 768px )
        width: calc( 100% / 4 )
        padding: 10px 15px
      @media only screen and ( min-width: 992px )
        padding: 10px 20px
      @media only screen and ( min-width: 1200px )
        padding: 15px 30px
      @media only screen and ( min-width: 1441px )
        padding: 15px 40px
      @media only screen and ( min-width: 1551px )
        padding: 20px 50px
      &:not(:last-of-type)
        @media only screen and ( min-width: 768px )
          border-right: 2px solid #ffff
      .conterBx
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: center
        width: 100%
        .icon
          border-radius: 50%
          background: #fff
          display: flex
          align-items: center
          justify-content: center
          width: 65px
          height: 65px
          @media only screen and ( min-width: 768px )
            width: 75px
            height: 75px
          @media only screen and ( min-width: 1200px )
            width: 85px
            height: 85px
          @media only screen and ( min-width: 1551px )
            width: 95px
            height: 95px
          svg
            height: auto
            width: 35px
            @media only screen and ( min-width: 768px )
              width: 45px
            @media only screen and ( min-width: 1200px )
              width: 56px
        .txtWrap
          width: 100%
          display: flex
          align-items: center
          justify-content: center
          margin-top: 25px
          .count
            font-family: "Archivo", sans-serif
            font-weight: 600
            line-height: 1
            text-align: left
            padding-right: 10px
            white-space: nowrap
            font-size: 18px
            @media only screen and ( min-width: 768px )
              font-size: 22px
            @media only screen and ( min-width: 992px )
              font-size: 24px
            @media only screen and ( min-width: 1200px )
              font-size: 26px
            @media only screen and ( min-width: 1441px )
              font-size: 30px
            @media only screen and ( min-width: 1551px )
              font-size: 33px
          .txt
            font-family: "Archivo", sans-serif
            color: #333333
            font-weight: 400
            line-height: 24.21px
            text-transform: capitalize
            text-align: center
            font-size: 14px
            @media only screen and ( min-width: 576px )
              font-size: 16px
            @media only screen and ( min-width: 768px )
              font-size: 18px
            @media only screen and ( min-width: 992px )
              font-size: 20px
            @media only screen and ( min-width: 1200px )
              font-size: 22px
            @media only screen and ( min-width: 1441px )
              font-size: 24px
.formSec
  position: relative
  padding: 35px 0 70px
  .container
    padding: 0 15px
    width: 95%
    max-width: 95%
  &:after
    content: ""
    z-index: 0
    position: absolute
    background: #000
    width: 100%
    height: 100%
    left: 0
    bottom: 0
    opacity: .7

  .tleWrap
    max-width: 1315px
    margin: auto
    margin-bottom: 20px
    @media only screen and ( min-width: 992px )
      margin-bottom: 30px
    @media only screen and ( min-width: 1200px )
      margin-bottom: 40px
    @media only screen and ( min-width: 1551px )
      margin-bottom: 50px
    .hd
      font-family: "Amita", serif
      font-weight: 400
      line-height: 1.5
      text-align: center
      position: relative
      color: #fff
      font-size: 16px
      margin-bottom: 30px
      @media only screen and ( min-width: 768px )
        font-size: 20px
      @media only screen and ( min-width: 992px )
        font-size: 22px
        margin-bottom: 40px
      @media only screen and ( min-width: 1200px )
        font-size: 26px
        margin-bottom: 40px
      @media only screen and ( min-width: 1551px )
        font-size: 28px
        margin-bottom: 50px
      @media only screen and ( min-width: 1661px )
        font-size: 30px
      .icon
        position: absolute
        left: 0
        right: 0
        height: auto
        margin: auto
        overflow: hidden
        width: 230px
        @media only screen and ( min-width: 1200px )
          width: 250px
        @media only screen and ( min-width: 1661px )
          width: 299px
        img, svg
          width: 100%
          height: 100%
          object-fit: cover
    p
      font-family: "Archivo", serif
      font-weight: 300
      line-height: 1.5
      text-align: center
      font-size: 12px
      @media only screen and ( min-width: 576px )
        font-size: 14px
      @media only screen and ( min-width: 992px )
        font-size: 16px
      @media only screen and ( min-width: 1200px )
        font-size: 18px
  .bgImg
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 100%
    overflow: hidden
    img
      width: 100%
      height: 100%
      object-fit: cover
  .formBx
    position: relative
    z-index: 1
    .flexBx
      display: flex
      flex-wrap: wrap
      // align-items: center
      padding-top: 30px
      @media only screen and ( min-width: 1200px )
        padding-top: 40px
      .lft
        width: 100%
        form
          .row
            display: flex
            flex-wrap: wrap
            justify-content: center
            margin: -7px
            @media only screen and ( min-width: 768px )
              margin: -10px
            @media only screen and ( min-width:1200px )
              margin: -15px
            @media only screen and ( min-width: 1441px )
              margin: -20px
            .col-item
              padding: 7px
              width: calc( 100% / 1 )
              @media only screen and ( min-width: 400px )
                width: calc( 100% / 2 )
              @media only screen and ( min-width: 600px )
                width: calc( 100% / 3 )
              @media only screen and ( min-width: 768px )
                padding: 10px
              @media only screen and ( min-width: 800px )
                width: calc( 100% / 5 )
              @media only screen and ( min-width:1200px )
                padding: 15px
              @media only screen and ( min-width: 1441px )
                padding: 20px
              .form-group
                margin-bottom: 0
                .form-box
                  position: relative
                  background: transparent
                  border: none
                  display: flex
                  flex-wrap: wrap
                  label
                    width: 100%
                    color: #fff
                    font-family: "Archivo", serif
                    text-transform: capitalize
                    font-size: 14px
                    @media only screen and ( min-width: 992px )
                      font-size: 16px
                    @media only screen and ( min-width: 1441px )
                      font-size: 18px
                  .icon
                    position: absolute
                    right: 13px
                    z-index: 1
                    width: 18px
                    height: 18px
                    bottom: 14px
                    svg
                      width: 100%
                      height: 100%
                      object-fit: contain
                .form-control
                  width: 100%
                  border: none
                  border-radius: 0
                  box-shadow: none
                  padding-left: 20px
                  font-size: 14px
                  font-family: "Archivo", serif
                  height: 40px
                  @media only screen and ( min-width: 768px )
                    height: 45px
                  @media only screen and ( min-width: 1441px )
                    height: 48px
                  @media only screen and ( min-width: 1551px )
                    height: 50px
                  &:focus
                    border: none
                    box-shadow: none
                select
                  option
                    &:hover
                      background: #F2A90D
                      color: #fff
                .react-datepicker-wrapper
                  width: 100%
                  .react-datepicker__input-container
                    .react-datepicker__calendar-icon
                      position: absolute
                      top: 0
                      bottom: 0
                      margin: auto
                      display: none

      .ryt
        display: flex
        align-items: flex-end
        width: 100%
        @media only screen and ( min-width: 1200px )
          width: 160px
        @media only screen and ( min-width: 1441px )
          width: 160px
        @media only screen and ( min-width: 1551px )
          width: 208px
        .btnBx
          width: 100%
          height: auto
          .findBtn
            display: flex
            background: #F2A90D
            color: #fff
            text-decoration: none
            display: flex
            align-items: center
            justify-content: center
            width: 160px
            height: 45px
            margin: auto
            margin-top: 20px
            @media only screen and ( min-width: 1200px )
              margin: 0
              margin-top: 0px
            @media only screen and ( min-width: 1441px )
              width: 160px
              height: 48px
            @media only screen and ( min-width: 1551px )
              height: 50px
              width: 208px
            span
              font-size: 20px
.placeSec
  padding: 30px 0
  @media only screen and ( min-width: 768px )
    padding: 50px 0 40px
  @media only screen and ( min-width: 992px )
    padding: 70px 0 60px
  @media only screen and ( min-width: 1200px )
    padding: 90px 0 80px
  @media only screen and ( min-width: 1441px )
    padding: 120px 0 100px
  .container
    padding: 0 15px
    width: 95%
    max-width: 95%
  .dFlex
    display: flex
    flex-wrap: wrap
    align-items: center
    @media only screen and ( min-width: 720px )
      flex-direction: column-reverse
    @media only screen and ( min-width: 1200px )
      flex-direction: row
    .lft
      width: 100%
      padding-top: 30px
      @media only screen and ( min-width: 1200px )
        width: calc( 100% - 400px )
        padding-top: 0
      @media only screen and ( min-width: 1441px )
        width: calc( 100% - 500px )
      @media only screen and ( min-width: 1661px )
        width: calc( 100% - 610px )
      // width: 356px
      .innerBx
        width: 100%
        display: flex
        flex-wrap: wrap
        align-items: center
        .carslBx
          width: 300px
          margin: auto
          @media only screen and ( min-width: 720px )
            margin: 0
          @media only screen and ( min-width: 992px )
            width: 320px
          @media only screen and ( min-width: 1441px )
            width: 355px
          .carousel
            width: 100%
            .carousel-indicators
              display: none
            .Carousel.Item
              width: 100%
          .imgBx
            width: 100%
            position: relative
            border-radius: 251px
            height: 100%
            overflow: hidden
            &:hover
              img
                transform: scale(1.1)
            img
              width: 100%
              height: 100%
              object-fit: cover
              transition: all .6s
            .logo
              position: absolute
              left: 0
              right: 0
              top: 0
              bottom: 0
              margin: auto
              width: 170px
              height: 70px
              @media only screen and ( min-width: 768px )
                width: 220px
                height: 100px
              @media only screen and ( min-width: 1441px )
                width: 245px
                height: 115px
              img
                width: 100%
                height: 100%
                object-fit: contain
                transition: all 0.6s

        .cont
          width: 100%
          padding-top: 25px
          @media only screen and ( min-width: 720px )
            padding-top: 0
            padding-left: 25px
            width: calc( 100% - 300px )
          @media only screen and ( min-width: 992px )
            padding-left: 30px
            width: calc( 100% - 320px )
          @media only screen and ( min-width: 1200px )
            width: calc( 100% - 355px )
          @media only screen and ( min-width: 1441px )
            padding-left: 40px
          @media only screen and ( min-width: 1661px )
            padding-left: 80px
          @media only screen and ( min-width: 1771px )
            padding-left: 120px
          .sHd
            font-family: "Archivo", sans-serif
            font-weight: 300
            line-height: 1.3
            text-align: left
            font-size: 16px
            text-align: center
            @media only screen and ( min-width: 720px )
              text-align: left
            @media only screen and ( min-width: 992px )
              font-size: 18px
            @media only screen and ( min-width: 1441px )
              font-size: 20px
            @media only screen and ( min-width: 1551px )
              font-size: 22px

          .hd
            font-family: "Amita", serif
            font-weight: 400
            line-height: 1.5
            text-align: left
            color: #F2A90D
            font-size: 24px
            margin-bottom: 15px
            text-align: center
            @media only screen and ( min-width: 720px )
              text-align: left
            @media only screen and ( min-width: 768px )
              font-size: 28px
              margin-bottom: 15px
            @media only screen and ( min-width: 992px )
              font-size: 32px
            @media only screen and ( min-width: 1200px )
              font-size: 36px
              margin-bottom: 20px
            @media only screen and ( min-width: 1441px )
              font-size: 40px
            @media only screen and ( min-width: 1551px )
              margin-bottom: 30px
              font-size: 46px
            @media only screen and ( min-width: 1661px )
              font-size: 48px
          p
            font-family: "Archivo", sans-serif
            color: #000000
            font-weight: 200
            line-height: 1.3
            text-align: left
            font-size: 14px
            text-align: center
            @media only screen and ( min-width: 720px )
              text-align: left
            @media only screen and ( min-width: 992px )
              font-size: 16px
            @media only screen and ( min-width: 1441px )
              font-size: 18px

    .ryt
      width: 400px
      margin: auto
      @media only screen and ( min-width: 1200px )
        margin: 0
      @media only screen and ( min-width: 1441px )
        width: 500px
      @media only screen and ( min-width: 1661px )
        width: 610px
      .imgBxR
        width: 100%
        height: auto
        overflow: hidden
        &:hover
          img
            transform: scale(1.1)
        img
          width: 100%
          height: 100%
          object-fit: cover
          transition: all .6s

.pacakage
  padding: 30px 0
  @media only screen and ( min-width: 768px )
    padding: 50px 0
  @media only screen and ( min-width: 1200px )
    padding: 70px 0
  @media only screen and ( min-width: 1441px )
    padding: 90px 0
  .container
    width: 95%
    max-width: 95%
    padding: 0 15px
    &.w-100
      @media only screen and ( min-width: 1441px )
        width: 100%
        max-width: 100%
        padding: 0

    .tleWrap
      max-width: 1315px
      margin: auto
      margin-bottom: 20px
      @media only screen and ( min-width: 992px )
        margin-bottom: 30px
      @media only screen and ( min-width: 1200px )
        margin-bottom: 40px
      @media only screen and ( min-width: 1551px )
        margin-bottom: 50px
      .hd
        font-family: "Amita", serif
        font-weight: 400
        line-height: 1.5
        text-align: center
        position: relative
        font-size: 18px
        margin-bottom: 30px
        @media only screen and ( min-width: 768px )
          font-size: 22px
        @media only screen and ( min-width: 992px )
          font-size: 24px
          margin-bottom: 40px
        @media only screen and ( min-width: 1200px )
          font-size: 28px
          margin-bottom: 40px
        @media only screen and ( min-width: 1551px )
          font-size: 32px
          margin-bottom: 50px
        @media only screen and ( min-width: 1661px )
          font-size: 36px
        .icon
          position: absolute
          left: 0
          right: 0
          height: auto
          margin: auto
          overflow: hidden
          width: 230px
          @media only screen and ( min-width: 1200px )
            width: 250px
          @media only screen and ( min-width: 1661px )
            width: 299px
          img, svg
            width: 100%
            height: 100%
            object-fit: cover
      p
        font-family: "Archivo", serif
        font-weight: 300
        line-height: 1.5
        text-align: center
        font-size: 12px
        @media only screen and ( min-width: 576px )
          font-size: 14px
        @media only screen and ( min-width: 992px )
          font-size: 16px
        @media only screen and ( min-width: 1200px )
          font-size: 18px
    .mainBx
      width: 100%
      .dFlex
        display: flex
        flex-wrap: wrap
        justify-content: center
        .item
          width: calc( 100% / 1 )
          @media only screen and ( min-width: 500px )
            width: calc( 100% / 2 )
          @media only screen and ( min-width: 768px )
            width: calc( 100% / 2 )
          @media only screen and ( min-width: 1200px )
            width: calc( 100% / 3 )
          .packageBx
            text-decoration: none
            display: flex
            overflow: none
            width: 100%
            padding-top: 35px
            transition: all 0.6s ease
            height: 265px
            @media only screen and ( min-width: 768px )
              height: 300px
            @media only screen and ( min-width: 1441px )
              height: 410px
            &:hover
              padding-top: 0px
              // @media only screen and (min-width: 576px)
              //   transform: translateY(4px)
              // @media only screen and (min-width: 992px)
              //   transform: translateY(6px)
              // @media only screen and (min-width: 1551px)
              //   transform: translateY(-10px)
              transition: all 0.6s ease
              .imgWrap
                .tleBx
                  opacity: 0
                  height: 0

                .contBx
                  opacity: 1
                  height: auto
            .imgWrap
              position: relative
              width: 100%
              height: 100%
              display: flex
              align-items: center
              &:after
                content: ""
                position: absolute
                z-index: 0
                left: 0
                bottom: 0
                width: 100%
                height: 100%
                background: #0000004d
              .imgBx
                position: absolute
                z-index: 0
                left: 0
                width: 100%
                height: 100%
                overflow: hidden
                img
                  width: 100%
                  height: 100%
                  object-fit: cover
              .tleBx
                z-index: 1
                max-width: 435px
                opacity: 1
                transition: all 0.6s ease
                position: absolute
                width: 100%
                height: 100%
                left: 0
                right: 0
                top: 0
                bottom: 0
                margin: auto
                transition: all 0.6s ease
                display: flex
                align-items: center
                justify-content: center
                padding: 40px
                .hd
                  color: #fff
                  font-size: 18px
                  font-family: "Archivo", serif
                  font-weight: 600
                  line-height: 1.2
                  text-align: center
                  @media only screen and (min-width: 576px)
                    font-size: 20px
                  @media only screen and (min-width: 768px)
                    font-size: 24px
                  @media only screen and (min-width: 992px)
                    font-size: 28px
                  @media only screen and (min-width: 1441px)
                    font-size: 32px

              .contBx
                max-width: 435px
                margin: auto
                z-index: 1
                opacity: 0
                height: 0
                transition: all 0.8s ease
                padding: 15px
                @media only screen and ( min-width: 768px )
                  padding: 20px
                @media only screen and ( min-width: 1200px )
                  padding: 30px
                @media only screen and ( min-width: 1441px )
                  padding: 40px
                .tle
                  color: #fff
                  font-size: 18px
                  font-family: "Archivo", serif
                  font-weight: 600
                  line-height: 1.2
                  text-align: center
                  transition: all 0.6s ease
                  display: -webkit-box
                  -webkit-line-clamp: 1
                  -webkit-box-orient: vertical
                  margin-bottom: 15px
                  overflow: hidden
                  @media only screen and (min-width: 576px)
                    font-size: 20px
                    margin-bottom: 20px
                  @media only screen and (min-width: 768px)
                    font-size: 24px
                  @media only screen and (min-width: 992px)
                    font-size: 28px
                    margin-bottom: 25px
                  @media only screen and (min-width: 1441px)
                    font-size: 32px
                .info
                  font-size: 10px
                  color: #fff
                  font-family: "Inter", sans-serif
                  font-weight: 500
                  line-height: 1.3
                  text-align: center
                  transition: all 1s ease-in-out
                  display: -webkit-box
                  -webkit-line-clamp: 8
                  -webkit-box-orient: vertical
                  overflow: hidden
                  @media only screen and (min-width: 576px)
                    font-size: 18px
                  @media only screen and (min-width: 992px)
                    font-size: 18px

                .btnBx
                  background-color: #F2A90D
                  margin: auto
                  width: fit-content
                  margin-top: 20px
                  padding: 8px 10px
                  transition: all 0.6s ease
                  @media only screen and (min-width: 768px)
                    margin-top: 25px
                    padding: 10px 15px
                  @media only screen and (min-width: 1200px)
                    margin-top: 30px
                    padding: 12px 20px
                  @media only screen and (min-width: 1441px)
                    padding: 15px 20px
                    margin-top: 40px
                  &:hover
                    background-color: #f2a90d4a
                    border: 1px solid #fff
                    span
                      // color: #F2A90D
                  span
                    font-family: "Inter", sans-serif
                    font-weight: 600
                    color: #fff
                    line-height: 1.3
                    text-align: center
                    font-size: 12px
                    @media only screen and (min-width: 768px)
                      font-size: 14px
                    @media only screen and (min-width: 992px)
                      font-size: 17px
                    @media only screen and (min-width: 1441px)
                      font-size: 19.64px

.serviceSec
  background: #f2a90d1a
  padding: 40px 0
  @media only screen and ( min-width: 768px )
    padding: 60px 0
  @media only screen and ( min-width: 1200px )
    padding: 80px 0
  .hd
    font-family: "Amita", serif
    font-weight: 400
    line-height: 1.5
    text-align: center
    position: relative
    font-size: 18px
    margin-bottom: 30px
    @media only screen and ( min-width: 768px )
      font-size: 22px
    @media only screen and ( min-width: 992px )
      font-size: 24px
      margin-bottom: 40px
    @media only screen and ( min-width: 1200px )
      font-size: 28px
      margin-bottom: 40px
    @media only screen and ( min-width: 1551px )
      font-size: 32px
      margin-bottom: 50px
    @media only screen and ( min-width: 1661px )
      font-size: 36px
    .icon
      position: absolute
      left: 0
      right: 0
      height: auto
      margin: auto
      overflow: hidden
      transition: all 0.6s ease
      width: 230px
      @media only screen and ( min-width: 1200px )
        width: 250px
      @media only screen and ( min-width: 1661px )
        width: 299px
      img, svg
        width: 100%
        height: 100%
        object-fit: cover
  .mainbx
    padding-top: 55px
    .flexBx
      display: flex
      flex-wrap: wrap
      align-items: center
      margin: -7.5px
      @media only screen and ( min-width: 360px )
        margin: -10px
      @media only screen and ( min-width: 768px )
        margin: -10px
      .item
        width: calc( 100% / 1 )
        padding: 7.5px
        @media only screen and ( min-width: 360px )
          padding: 10px
          width: calc( 100% / 2 )
        @media only screen and ( min-width: 768px )
          width: calc( 100% / 4 )
          padding: 10px
        .serviceBx
          display: block
          text-decoration: none
          .iconBx
            display: flex
            align-items: center
            justify-content: center
            background: #F2A90D
            border-radius: 50%
            overflow: hidden
            margin: auto
            width: 145px
            height: 145px
            margin-bottom: 15px
            @media only screen and ( min-width: 992px )
              width: 155px
              height: 155px
              margin-bottom: 20px
            @media only screen and ( min-width: 1200px )
              width: 175px
              height: 175px
              margin-bottom: 30px
            @media only screen and ( min-width: 1441px )
              width: 198px
              height: 198px
            &:hover
              svg
                opacity: 1
            svg
              height: auto
              transition: all .6s
              opacity: .4
              width: 45px
              @media only screen and ( min-width: 992px )
                width: 65px
              @media only screen and ( min-width: 1441px )
                width: 94px
          .name
            font-family: "Archivo", serif
            transition: all 0.6s ease
            color: #333333
            text-decoration: none
            font-weight: 500
            line-height: 1
            text-align: center
            margin-bottom: 10px
            font-size: 18px
            @media only screen and ( min-width: 768px )
              font-size: 20px
            @media only screen and ( min-width: 992px )
              font-size: 22px
            @media only screen and ( min-width: 1200px )
              font-size: 24px
            @media only screen and ( min-width: 1441px )
              font-size: 26px
          .sName
            font-family: "Archivo", serif
            color: #333333
            text-decoration: none
            font-weight: 300
            line-height: 1
            text-align: center
            font-size: 12px
            @media only screen and ( min-width: 768px )
              font-size: 14px
            @media only screen and ( min-width: 1441px )
              font-size: 16px
.adventureSec
  padding: 35px 0 30px
  @media only screen and ( min-width: 768px )
    padding: 30px 0 40px
  @media only screen and ( min-width: 992px )
    padding: 40px 0 60px
  @media only screen and ( min-width: 1200px )
    padding: 60px 0 80px
  @media only screen and ( min-width: 1441px )
    padding: 80px 0 100px
  @media only screen and ( min-width: 1551px )
    padding: 100px 0 120px
  @media only screen and ( min-width: 1771px )
    padding: 180px 0 200px
  .hd
    font-family: "Amita", serif
    font-weight: 400
    position: relative
    font-size: 22px
    line-height: 1
    margin-bottom: 30px
    text-align: center
    @media only screen and ( min-width: 768px )
      font-size: 24px
    @media only screen and ( min-width: 992px )
      font-size: 26px
      margin-bottom: 40px
    @media only screen and ( min-width: 1200px )
      width: fit-content
      text-align: left
      font-size: 28px
      margin-bottom: 40px
      line-height: 1.2
    @media only screen and ( min-width: 1551px )
      font-size: 32px
      margin-bottom: 50px
    @media only screen and ( min-width: 1661px )
      font-size: 36px
    .icon
      position: absolute
      left: 0
      right: 0
      height: auto
      margin: auto
      overflow: hidden
      transition: all 0.6s ease
      width: 230px
      @media only screen and ( min-width: 1200px )
        width: 250px
      @media only screen and ( min-width: 1661px )
        width: 299px
      img, svg
        width: 100%
        height: 100%
        object-fit: cover
  .container
    width: 95%
    max-width: 95%
    margin: auto
    padding: 0 15px
    .mainBx
      .flexBx
        display: flex
        flex-wrap: wrap
        .lft
          width: 100%
          padding-bottom: 30px
          @media only screen and ( min-width: 1200px )
            width: 360px
            padding-bottom: 0
          @media only screen and ( min-width: 1350px )
            width: 435px
          .contBx
            display: flex
            flex-wrap: wrap
            height: 100%
            align-content: space-between
            .cont
              p
                font-family: "Archivo", serif
                font-weight: 300
                line-height: 1.5
                font-size: 12px
                text-align: center
                @media only screen and ( min-width: 576px )
                  font-size: 14px
                @media only screen and ( min-width: 992px )
                  font-size: 16px
                @media only screen and ( min-width: 1200px )
                  text-align: left
                @media only screen and ( min-width: 1441px )
                  font-size: 18px
            .imgBx
              height: auto
              overflow: hidden
              margin: auto
              width: 210px
              margin-top: 15px
              @media only screen and ( min-width: 992px )
                width: 260px
              @media only screen and ( min-width: 1200px )
                width: 300px
                margin: 0
                margin-top: 20px
              @media only screen and ( min-width: 1350px )
                width: 356px
              img
                width: 100%
                height: 100%
                object-fit: cover
        .ryt
          width: 100%
          @media only screen and ( min-width: 1200px )
            width: calc(100% - 360px)
            padding-left: 30px
          @media only screen and ( min-width: 1350px )
            width: calc(100% - 435px)
          @media only screen and ( min-width: 1441px )
            padding-left: 55px
          @media only screen and ( min-width: 1551px )
            padding-left: 75px
          .swipeBx
            .swiper
              .swiper-wrapper
                .swiper-slide
                  pointer-events: none
                  width: 175px !important
                  height: 340px
                  @media only screen and ( min-width: 460px )
                    height: 420px
                    width: 205px !important
                  @media only screen and ( min-width: 1200px )
                    width: 175px !important
                  @media only screen and ( min-width: 1350px )
                    height: 540px
                    width: 200px !important
                  @media only screen and ( min-width: 1661px )
                    width: 230px !important
                    height: 630px
                  @media only screen and ( min-width: 1771px )
                    height: 700px
                  &.swiper-slide-active
                    pointer-events: all
                    width: 100% !important
                    @media only screen and ( min-width: 460px )
                      width: 325px !important
                    @media only screen and ( min-width: 1200px )
                      width: 290px !important
                    @media only screen and ( min-width: 1350px )
                      width: 350px !important
                    @media only screen and ( min-width: 1661px )
                      width: 450px !important
                    .sliderBx
                      &:hover
                      .imgWrap
                        overflow: hidden
                        &:hover
                          &:after
                            content: ""
                      .contBx
                        .priceBx
                          opacity: 1 !important
                        .info
                          opacity: 1 !important
                  .sliderBx
                    text-decoration: none
                    display: flex
                    width: 100%
                    // padding-top: 35px
                    transition: all 0.6s ease
                    height: 100%
                    // @media only screen and ( min-width: 768px )
                    //   height: 300px
                    // @media only screen and ( min-width: 1441px )
                    //   height: 410px
                    &:hover
                      padding-top: 0px
                      // @media only screen and (min-width: 576px)
                      //   transform: translateY(4px)
                      // @media only screen and (min-width: 992px)
                      //   transform: translateY(6px)
                      // @media only screen and (min-width: 1551px)
                      //   transform: translateY(-10px)
                      transition: all 0.6s ease
                      .imgWrap
                        .tleBx
                          opacity: 0
                          height: 0

                        .contBx
                          opacity: 1
                          height: auto
                    .imgWrap
                      position: relative
                      width: 100%
                      height: 100%
                      display: flex
                      align-items: center
                      &:after
                        // content: ""
                        position: absolute
                        z-index: 0
                        left: 0
                        bottom: 0
                        width: 100%
                        height: 100%
                        background: #00000082
                        transition: all 0.6s ease
                      .imgBx
                        position: absolute
                        z-index: 0
                        left: 0
                        width: 100%
                        height: 100%
                        overflow: hidden
                        img
                          width: 100%
                          height: 100%
                          object-fit: cover
                      .tleBx
                        z-index: 1
                        max-width: 435px
                        opacity: 1
                        transition: all 0.6s ease
                        position: absolute
                        width: 100%
                        height: fit-content
                        left: 0
                        right: 0
                        // top: 0
                        bottom: 0
                        margin: auto
                        transition: all 0.6s ease
                        display: flex
                        align-items: center
                        justify-content: center
                        padding: 20px 10px
                        @media only screen and ( min-width: 1551px )
                          padding: 30px 15px
                        @media only screen and ( min-width: 1661px )
                          padding: 40px 20px
                        .hd
                          color: #fff
                          font-size: 18px
                          font-family: "Amita", serif
                          font-weight: 600
                          line-height: 1.2
                          text-align: center
                          @media only screen and (min-width: 576px)
                            font-size: 20px
                          @media only screen and (min-width: 768px)
                            font-size: 24px
                          @media only screen and (min-width: 992px)
                            font-size: 28px
                          @media only screen and (min-width: 1441px)
                            font-size: 32px

                      .contBx
                        max-width: 435px
                        margin: auto
                        z-index: 1
                        opacity: 0
                        height: 0
                        transition: all 0.8s ease
                        padding: 15px
                        @media only screen and ( min-width: 768px )
                          padding: 20px
                        @media only screen and ( min-width: 1200px )
                          padding: 30px
                        @media only screen and ( min-width: 1441px )
                          padding: 40px
                        @media only screen and ( min-width: 1551px )
                          padding: 60px
                        .tle
                          color: #fff
                          font-size: 18px
                          font-family: "Amita", serif
                          font-weight: 600
                          line-height: 1.2
                          text-align: center
                          transition: all 0.6s ease
                          display: -webkit-box
                          -webkit-line-clamp: 1
                          -webkit-box-orient: vertical
                          margin-bottom: 15px
                          overflow: hidden
                          @media only screen and (min-width: 576px)
                            font-size: 20px
                          @media only screen and (min-width: 768px)
                            font-size: 24px
                          @media only screen and (min-width: 992px)
                            margin-bottom: 20px
                            font-size: 28px
                            margin-bottom: 25px
                          @media only screen and (min-width: 1441px)
                            font-size: 32px
                        .priceBx
                          opacity: 0
                          color: #fff
                          font-family: "Archivo", serif
                          font-weight: 400
                          line-height: 1.3
                          text-align: center
                          transition: all 0.6s ease
                          margin-bottom: 15px
                          font-size: 12px
                          @media only screen and (min-width: 992px)
                            margin-bottom: 20px
                            font-size: 14px
                          @media only screen and (min-width: 1200px)
                            margin-bottom: 25px
                          @media only screen and (min-width: 1441px)
                            font-size: 16px
                            margin-bottom: 35px
                          span
                            display: inline-block
                            padding-left: 5px
                            font-size: 16px
                            @media only screen and (min-width: 992px)
                              font-size: 18px
                            @media only screen and (min-width: 1200px)
                              font-size: 20px
                            @media only screen and (min-width: 1441px)
                              font-size: 24px

                        .info
                          opacity: 0
                          font-size: 13px
                          color: #fff
                          font-family: "Archivo", serif
                          font-weight: 400
                          line-height: 1.3
                          text-align: center
                          transition: all 0.6s ease
                          display: -webkit-box
                          -webkit-line-clamp: 3
                          -webkit-box-orient: vertical
                          overflow: hidden
                          @media only screen and (min-width: 576px)
                            font-size: 14px
                          @media only screen and (min-width: 992px)
                            font-size: 16px
                          @media only screen and (min-width: 1441px)
                            font-size: 18px

  ////////// animation///////

@keyframes bounce
  0%, 20%, 50%, 80%, 100%
    transform: translateY(0)

  40%
    transform: translateY(-10px)

  60%
    transform: translateY(-5px)
