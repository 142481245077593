.testimonials {
  // background-color: #000000;
  background-color: rgba(242, 169, 13, 0.1019607843);
  overflow: hidden;
  z-index: 0;

  .testimonials_container {
    display: block;
    position: relative;
    // text-transform: uppercase;
    // clip-path: polygon(100% 0%, 100% 98%, 0 100%, 0% 2%);
    padding: 60px 20px;
    z-index: 0;

    h3 {
      font-family: "Amita", serif;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      color: #000000;
      text-transform: uppercase;

      div {
        display: inline-block;
        background: #dab289;
        transform: skew(-10deg);
        font-style: normal;
        padding: 0px 10px;
        border-radius: 6px;

        span {
          display: inline-block;
          transform: skew(10deg);
        }
      }
    }

    .underline_icon {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    p {
      margin-top: 10px;
      font-family: Plus Jakarta Sans;
      font-size: 18px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.01em;
      text-align: center;

      color: #000000;
    }

    .slider_container {
      display: flex;
      flex-direction: column;
      overflow: visible;
      justify-content: center;
      gap: 30px;
      margin-top: 60px;

      @media only screen and (max-width: 992px) {
        padding: 20px;
      }

      .swiper_custom_btns {
        display: flex;
        justify-content: center;
        gap: 20px;

        button {
          background: transparent;
          padding: 5px;
          border: 1px solid #000000;
          border-radius: 50%;

          img {
          }
        }
      }

      .card {
        // background-color: #f2aa0d72;
        background-color: hsl(208, 25%, 20%);
        padding: 50px 40px;
        border-radius: 20px;
        width: 50%;
        height: auto;
        opacity: 0.75;
        transform: translateY(20px);
        transition: transform 0.7s ease, box-shadow 0.7s ease-in-out;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;

        &:hover {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.599);
        }

        .avatar_container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .avatar_image {
            overflow: hidden;
            width: 100px;
            height: 100px;
            border-radius: 50%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .username_container {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-family: Poppins;
              font-size: 20px;
              font-weight: 600;
              line-height: 36px;
              text-align: left;
              color: #ffffff;
            }
          }

          .icon_container {
            // float: right;
          }
        }

        p {
          font-family: "Archivo", serif;
          font-size: 20px;
          font-weight: 300;
          line-height: 40.8px;
          text-align: left;
          text-transform: unset !important;
          color: #ffffff;


          @media only screen and (max-width: 700px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
