/* CartButton.css */

.cart-button {
    display: flex;
    align-items: center;
  }
  
  .quantity-input {
    width: 50px;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  button {
    padding: 8px 16px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  