.slide {
  position: relative; /* Make the slide a positioning context */
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.slide-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)); /* Gradient overlay */
  z-index: 1; /* Ensure the gradient appears above the image */
}

.slide img {
  position: relative;
  z-index: 0; /* Ensure the image is behind the gradient */
}
